*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    background: #3f2b46;
    overflow: hidden;
    pointer-events: all;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}
.start
{
    display: grid; 
    grid-template-columns: 100%;  
    grid-template-rows: 325px 200px;  
    border: none;
    position: absolute;  
    text-align: center;
    pointer-events: all;
    gap: 20px;
    width: 100%;
    bottom: 10px;
}
.init{
    text-align: center;
}
.intro
{      
    position: absolute;
    bottom: 60px;
    display: block;    
    font-family: 'Press Start 2P',display;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 70px;
    line-height: 1.2em;
    color: white;
    pointer-events: all; 
    width: 100%;   
    height: 140px;
    margin: auto;
    text-align: center;
    animation-name: fadeinout;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
.skip
{      
    position: absolute;
    bottom: 0;
    display: block;    
    font-family: 'Press Start 2P',display;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 1.2em;
    color: white;
    pointer-events: all; 
    width: 40%;   
    height: 50px;
    right: 0px;
    text-align: center;
    opacity: .5;
}
.intro.invisible
{
    display: none;
}
.skip.invisible
{
    display: none;
}
.welcome
{
    grid-row: span 1;    
    font-family: 'Press Start 2P',display;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 70px;
    line-height: 1.2em;
    color: white;
    pointer-events: all;
    text-align: center;       
    grid-row-start: 2;
    display: none;
}
.welcome.visible{display: block;}
.mining
{
    grid-row: span 1;
    height: 100%;
    display: none;
    margin-left: auto;
    margin-right: auto;
    grid-row-start: 1;
}
.mining.visible{display: block;}
.arrow{
    position: absolute;
    pointer-events: none;
    margin-left: auto;
    margin-right: auto;
    left:50%;
    transform: translate(-50%, 0px);
    bottom: 0px;
    width: 50px;
    height: 50px;    
    content:url("./images/pixelArrow.png");
    border: none;
    opacity: 0; 
    display: none;
    transition: opacity 1s;
    animation-name: fadeinout;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}
.arrow.visible{
    opacity: 1; 
    display: block;
}
.loading-bar
{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #ffffff;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s;
}
.loading-bar.ended
{
    transform: scaleX(0);
    transform-origin:100% 0;
    transition: transform 1.5s ease-in-out;
}
.fader
{
  position: absolute;
  background: #3f2b46;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 1;
  transition: opacity 0.5s;
  pointer-events: none;
  order: 10;
}
.fader.invisible{opacity: 0;}
@media only screen and (max-width: 700px) 
{
  .intro{font-size: 30px;}
  .welcome{font-size: 30px;}
  .start{grid-template-rows: 190px 200px;}
  .arrow{
    bottom: 10px;
    width: 40px;
    height: 40px;}
  .skip{font-size: 10px;width: 70%;}
}
@keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }